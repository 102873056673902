import React from 'react';
import PropTypes from 'prop-types';
import useAnimateOnScroll from '../../utils/useAnimateOnScroll';
import Project from '../Project';
import slugify from '../../utils/slugify';


import './index.scss';

function Projects({ projects }) {
    useAnimateOnScroll();
  
    const leftProjects = [];
    const rightProjects = [];
  
    projects.forEach((project, idx) => {
      const slug = `/${slugify(project.title)}/`;
  
      if (idx % 2 === 0) {
        leftProjects.push(<Project key={slug} link={slug} {...project} />);
      } else {
        rightProjects.push(<Project key={slug} link={slug} {...project} />);
      }
    });
  
    return (
      <section id="projects" className="projects section-padding bg-lightgrey">
        <div className="grid">
          <h2 className="projects__title">
             <span data-aos="slice-up" data-aos-duration="400" data-aos-delay="100">Projects</span>
          </h2>
          <div className="projects__container">
            <div>
              {leftProjects}
            </div>
            <div>
              {rightProjects}
            </div>
          </div>
          <div className="projects__link-container">
            <a href="/projects" className="projects__archive-link">View full project archive</a>
          </div>
        </div>
      </section>
    );
  }
  
  Projects.propTypes = {
    projects: PropTypes.array.isRequired,
  };
  
  export default Projects;
  